import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello👋
    </Text>
    <p>{`I'm Maxence Frenette, a developper passionate with new web technologies. This website is not a blog, it's a `}<a parentName="p" {...{
        "href": "https://joelhooks.com/digital-garden"
      }}>{`digital garden`}</a>{`. You can check out my `}<a parentName="p" {...{
        "href": "/projects"
      }}>{`projects`}</a>{` or some articles I wrote below.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      